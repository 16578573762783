
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment/moment";
import Swal from "sweetalert2/dist/sweetalert2.js";

import ApiService from "@/core/services/ApiService";
import EntityInput from "@/components/EntityInput.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import router from "@/router";

export default defineComponent({
  name: "compensation-form",
  props: {
    mode: {
      type: String,
      required: false,
      default: "new",
    },
  },
  components: {
    EntityInput,
    EntitySelect,
  },
  computed: {
    publisherPlaceholder() {
      return this.loadingPublishers ? 'Loading...' : (this.entityData.affiliate_id == null) ? 'Select affiliate first' : 'Select';
    }
  },
  data() {
    return {
      loadingPublishers: false,
      preview: null,
      errors: {},
      loading: false,
      entityType: "offer",
      entityData: {
        confirmed: false,
        affiliate_id: null,
        publisher_id: null,
        leads: [],
        date_interval: [],
        offer_id: null,
        origin: ['pending', 'rejected', 'trash'],
        count: null,
        guaranteed: null,
        type: null,
      },
      statsData: {},
      affiliateList: [],
      publisherList: [],
      offerList: [],
    };
  },
  methods: {
    changedDate(e) {
      this.retrieveData(e);
      console.log('date');
    },
    changedOffer(e) {
      this.retrieveData(e);
      console.log('offer');
    },
    changedPublisher(e) {
      this.retrieveData(e);
      console.log('publisher');
    },
    changedOrigin(e) {
      this.entityData.origin = e;
      this.retrieveData(e);
    },
    changedAffiliate(e) {
      this.entityData.publisher_id = null;
      this.retrieveData(e);
      this.loadingPublishers = true;
      ApiService.query("publishers", {
        params: { perPage: 9999, filters: { affiliateId: this.entityData.affiliate_id }}
      }).then((response) => {
        this.publisherList = response.data.data;
      }).finally(() => {
        this.loadingPublishers = false;
      });
    },
    catchErrors(error) {
      Swal.fire({
        text:  error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    onSaveSuccess(response) {
      this.$router.back();
    },
    retrieveData(e) {
      this.preview = null;
      if(this.entityData.type && this.entityData.affiliate_id && this.entityData.offer_id && this.entityData.date_interval[0] && this.entityData.date_interval[1] && this.entityData.count && this.entityData.origin[0]) {
        this.loading = true;
        ApiService.post('compensations/simulate', { ...this.entityData })
          .then((result) => {
            this.entityData.confirmed = false;
            this.entityData.leads = result.data.data.selected_leads;
            this.errors = result.data.errors;
            this.loading = false;
            this.preview = result.data.data;
          })
          .catch((err) => {
            this.catchErrors(err);
          }).finally(() => {
            this.loading = false;
        });
      }
      return;

    },
    submitData() {
      ApiService.post('compensations', { ...this.entityData })
        .then((result) => {
          router.push({
            name: 'compensation-list',
          });
        })
        .catch((err) => {
          this.catchErrors(err);
        }).finally(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    ApiService.query("affiliates", {
      params: { perPage: 9999, filters: {} },
    }).then((response) => {
      this.affiliateList = response.data.data;
    });

    ApiService.query("offer_list", {
      params: { perPage: 9999 },
    }).then((response) => {
      this.offerList = response.data.data;
    });
  },

  setup() {
    const shortcuts = [
      {
        text: "Today",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setHours(0, 0, 0);
          end.setHours(23, 59, 59);
          return [start, end];
        },
      },
      {
        text: "Yesterday",
        value: () => {
          const start = new Date();
          const end = new Date();
          start.setTime(start.getTime() - 3600 * 24 * 1000);
          start.setHours(0, 0, 0);
          end.setTime(end.getTime() - 3600 * 24 * 1000);
          end.setHours(23, 59, 59);
          return [start, end];
        },
      },
      {
        text: "Last week",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        },
      },
      {
        text: "Last 30 days",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        },
      },
      {
        text: "This month",
        value: () => {
          const end = new Date();
          return [moment().startOf("month").toDate(), end];
        },
      },
      {
        text: "Last month",
        value: () => {
          return [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ];
        },
      },
    ];


    onMounted(() => {
      setCurrentPageTitle("Compensations");
    });

    return { shortcuts };

  },
});
