
import { defineComponent, ref, watch } from "vue";

import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "entitySelect",
  props: {
    optionList: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: String || Array,
      default: "",
    },
    optionLabel: {
      type: String,
      default: "label",
    },
    optionKey: {
      type: String,
      default: "key",
    },
    placeholder: String,
    errors: Object,
    entity: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      model: "",
      entityList: [],
    };
  },
  methods: {
    onChange(event) {
      this.$emit("update", event);
    },
  },
  setup(props, context) {
    let reactiveValue = ref(props.modelValue);

    watch(reactiveValue, () => {
      context.emit("update:modelValue", reactiveValue.value.toString());
    });

    return { reactiveValue };
  },
  mounted() {
    if (!this.optionList || this.optionList.length == 0) {
      ApiService.query(this.entity, {}).then((response) => {
        console.log(response);
        this.entityList = response.data.data;
      });
    } else {
      (this.entityList as any) = this.optionList;
    }
  },
});
